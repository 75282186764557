import $ from 'jquery';
import kdzDeleteRow from './deleteRow';

function loadTableData (elem) {
  let _this = elem;
  let modulPfad = _this.attr('data-path');
  let menuID = _this.attr('data-menu-id');
  let modul = _this.attr('data-modul');
  let modus = 'loadTable';
  let lengthLabels = [];
  let defaultLengths = [10, 25, 50, 100, 500, -1];
  let tableLengths = defaultLengths;

  // Array aufbereiten
  $.each(tableLengths, function (index, value) {
    if (value !== -1 && value !== '-1') {
      lengthLabels[index] = value;
    } else {
      lengthLabels[index] = 'alle';
    }
    tableLengths[index] = parseInt(value);
  });
  _this.find('#dataTableAjax').dataTable({
    'lengthMenu': [tableLengths, lengthLabels],
    responsive: true,
    stateSave: true,
    'stateSaveParams': function (settings, data) {
      data.search.search = ''; // Suche nicht speichern
    },
    stateDuration: 0,
    language: {
      url: 'assets/plugins/datatables/jquery.dataTables.de.json'
    },
    'deferRender': true,
    'processing': true,
    'serverSide': true,
    'ajax': {
      'url': 'ajax.php',
      'type': 'POST',
      'dataType': 'json',
      'async': true,
      'data': {
        modus: modus,
        modulPfad: modulPfad,
        menuID: menuID,
        modul: modul
      }
    },
    'initComplete': function (settings, json) {
      kdzDeleteRow();
      changeColor();
    },
    'drawCallback': function (settings) {
      kdzDeleteRow();
      changeColor();
    }
  })
    .on('order.dt', function (evt, data) {
      kdzDeleteRow();
      changeColor();
    })
    .on('search.dt', function (evt, data) {
      kdzDeleteRow();
      changeColor();
    })
    .on('page.dt', function (evt, data) {
      kdzDeleteRow();
      changeColor();
    });

  _this.find('[data-table-ajax]').dataTable({
    'lengthMenu': [tableLengths, lengthLabels],
    responsive: true,
    stateSave: true,
    'stateSaveParams': function (settings, data) {
      data.search.search = ''; // Suche nicht speichern
    },
    stateDuration: 0,
    language: {
      url: 'assets/plugins/datatables/jquery.dataTables.de.json'
    },
    'deferRender': true,
    'processing': true,
    'serverSide': true,
    'ajax': {
      'url': 'ajax.php',
      'type': 'POST',
      'dataType': 'json',
      'async': true,
      'data': {
        modus: modus,
        modulPfad: modulPfad,
        menuID: menuID,
        modul: modul
      }
    },
    'initComplete': function (settings, json) {
      kdzDeleteRow();
      changeColor();
    },
    'drawCallback': function (settings) {
      kdzDeleteRow();
      changeColor();
    }
  })
    .on('order.dt', function (evt, data) {
      kdzDeleteRow();
      changeColor();
    })
    .on('search.dt', function (evt, data) {
      kdzDeleteRow();
      changeColor();
    })
    .on('page.dt', function (evt, data) {
      kdzDeleteRow();
      changeColor();
    });
}

function changeColor () {
  $('#dataTableAjax tr').each(function () {
     var _this = $(this);
    var val = 0;
     if(_this.find('.changeAngebotsStatus').length > 0) {
        val = $(_this.find('.changeAngebotsStatus')[0]).val();
     }
    if(_this.find('.load-color').length > 0) {
       val = $(_this.find('.load-color')[0]).attr('data-color');
    }
    val = parseInt(val)
    var color = ''
    switch(val){
      case 1:
        color = 'rgb(242,200,0, 0.3)';
        break;
      case 2:
        color = 'rgb(103,135,19, 0.3)';
        break;
      case 3:
        color = 'rgb(233,93,15, 0.3)';
        break;
      case 4:
        color = 'rgb(226,0,26, 0.3)';
        break;
      case 5:
        color = 'rgb(174,3,3, 0.3)';
        break;
      default:
        color = '';
    }

    _this.css('background-color', color)
  })
}
function setTableLength () {
  let _elem = $('#dataTable_length select[name=dataTable_length]');
  if (_elem.length > 0 && $.cookie('data_table_length')) {
    var dataTableLength = $.cookie('data_table_length');
    _elem.val(dataTableLength);
    _elem.trigger('change');
  }
}

function saveTableLength () {
  var value = $(this).val();
  var domain = $('#httpHost').val();
  $.cookie('data_table_length', value, {domain: domain, path: '/'});
}

export default function init () {
  $(document).ready(function () {
    $('[data-load-ajax="true"]').each(function () {
      loadTableData($(this));
    });

    $(document.body).on('change', '#dataTable_length select[name=dataTable_length]', saveTableLength);

    /* Datatables */
    let lengthLabels = [];
    let defaultLengths = [10, 25, 50, 100, 500, -1];
    let tableLengths = defaultLengths;

    // Array aufbereiten
    $.each(tableLengths, function (index, value) {
      if (value !== -1 && value !== '-1') {
        lengthLabels[index] = value;
      } else {
        lengthLabels[index] = 'alle';
      }
      tableLengths[index] = parseInt(value);
    });

    $('#dataTable').dataTable({
      'lengthMenu': [tableLengths, lengthLabels],
      responsive: true,
      stateSave: true,
      'stateSaveParams': function (settings, data) {
        data.search.search = ''; // Suche nicht speichern
      },
      stateDuration: 0,
      language: {
        url: 'assets/plugins/datatables/jquery.dataTables.de.json'
      }
    });
    setTableLength();
  });
}
